<template>
  <div class="MavenStage">
    <div class="AskMaven" :class="{ fullScreen: isFullScreen }">
      <!-- Error Screen -->
      <ask-maven-error v-if="showError" @close="showError = false" />

      <!-- Intro Screen -->
      <ask-maven-intro
        v-if="!showError && !loading && askMavenHistory.length === 0"
        :selectedChatId="selectedChatId"
        :showGroupLabelAndIcon="showGroupLabelAndIcon"
        :currentGroupName="currentGroupName"
        @sendMessage="handleSendEvent($event)"
        @clickGroupMenu="showGroupMenu = true"
      >
        <input
          type="text"
          v-model="inputText"
          :placeholder="$t('maven.regularPlaceholder')"
          :disabled="loading"
          @keyup.enter="() => sendMessage()"
        />
        <div class="InputButtonsContainer">
          <button class="InputSend" @click="() => sendMessage()" :disabled="isButtonDisabled">
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </button>
        </div>
      </ask-maven-intro>

      <section class="AskMaven__Thread" v-if="!loading || askMavenHistory.length !== 0">
        <div class="AskMaven__ItemWrapper" v-for="(thread, idx) in askMavenHistory" :key="thread.answerId">
          <div
            :class="[
              'Modal isRight',
              {
                isActive: activeModal === `modalSources-${thread.answerId}`,
                isInactive: activeModal !== `modalSources-${thread.answerId}`,
              },
            ]"
            @click="closeModal"
          >
            <div class="Modal__Container">
              <div class="Modal__Header">
                <h3>
                  <font-awesome-icon :icon="['far', 'rectangle-list']" />
                  {{ $t("askMaven.sources") }}
                </h3>
                <button class="AskMaven__ActionButton isIcon" @click="closeModal">
                  <font-awesome-icon :icon="['far', 'xmark']" />
                </button>
              </div>
              <div class="Modal__Body" v-if="thread.references && thread.references.length > 0">
                <!-- Vertical Sources -->
                <q-a-sources
                  :isVertical="true"
                  :thread="thread"
                  :referencesList="thread.references"
                  @openModal="openModal($event)"
                  @openPlayer="openPlayer"
                />
              </div>
            </div>
          </div>
          <div class="AskMaven__ThreadContainer" :class="{ workflowLevel: !isFullScreen }">
            <div class="AskMaven__Item">
              <h2 class="AskMaven__ItemTitle">{{ thread.question }}</h2>
              <div class="AskMaven__Object Sources" v-if="thread.references && thread.references.length > 0">
                <header>
                  <h3 class="AskMaven__Object_Title">
                    <font-awesome-icon :icon="['far', 'rectangle-list']" />
                    {{ $t("askMaven.sources") }}
                  </h3>
                </header>
                <!-- Horizontal Sources -->
                <q-a-sources
                  :isVertical="false"
                  :thread="thread"
                  :referencesList="thread.references"
                  @openModal="openModal($event)"
                  @openPlayer="openPlayer"
                />
              </div>
              <!-- Answer -->
              <ask-maven-answer :thread="thread" @copy="copy" @threadLike="sendThreadLike($event)">
                <div
                  class="message__list__item__text markdown"
                  v-html="parsingMarkdown(thread.answer, thread.references)"
                />
              </ask-maven-answer>
              <!-- Follow Up -->
              <ask-maven-recommended
                v-if="!loading && thread.followUpQuestions && idx === 0"
                :thread="thread"
                @clickItem="handleSendEvent($event)"
              />
            </div>
            <div
              class="AskMaven__Addons"
              v-if="thread.references && thread.references.length > 0 && typeof thread.references[0] !== 'object'"
            >
              <div
                class="AskMaven__AddonsItem"
                v-for="(reference, index) in referencesList.find(({ id }) => id === thread.answerId).workflows"
                :key="index + '_' + reference.id"
                @click="openPlayer({ workflowId: reference.id, stepId: reference.id })"
              >
                <button class="Button Small">
                  {{ $t("all.watch") }}
                  <font-awesome-icon :icon="['far', 'circle-play']" />
                </button>
                <d-image :src="reference.poster" :aspectRatio="16 / 9" />
              </div>
            </div>
            <!-- add empty container when there are no references to keep layout -->
            <div class="AskMaven__Addons" v-if="isFullScreen"></div>
          </div>
        </div>
      </section>
      <ask-maven-loading v-if="loading" is-workflow-level :is-full-screen="isFullScreen" />
      <section
        class="AskMaven__Thread isInput"
        :class="isFullScreen ? 'inputHeight--small' : 'inputHeight'"
        v-if="loading || askMavenHistory.length !== 0"
      >
        <div class="AskMaven__ThreadContainer isInput" :class="{ workflowLevel: !isFullScreen }">
          <div class="AskMaven__Item">
            <div class="InputWrap" :class="{ isDisabled: loading }">
              <input
                type="text"
                v-model="inputText"
                :placeholder="$t('maven.regularPlaceholder')"
                :disabled="loading"
                @keyup.enter="() => sendMessage()"
              />
              <button class="InputSend" @click="() => sendMessage()" :disabled="isButtonDisabled">
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
              </button>
            </div>
          </div>
          <div class="AskMaven__Thread clearHistory" v-if="askMavenHistory.length > 0">
            <d-button outline round @click="showClearDialog = true" v-if="!isFullScreen">
              <div class="clearBtn">
                <font-awesome-icon :icon="['far', 'xmark']" />
                {{ $t("askMaven.clearHistory") }}
              </div>
            </d-button>
          </div>
        </div>
      </section>
    </div>
    <d-confirmation-popup
      v-model="showClearDialog"
      :title="$t('askMaven.clearConfirmTitle')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('maven.clearConfirm')"
      :content="$t('askMaven.clearDesc')"
      @clickActionBtn="clearInWorkflowLevel"
    ></d-confirmation-popup>
  </div>
</template>
<script>
import { getChatById, likeMsg, getChatByWorkflowId, clearChatByWorkflowId } from "@/server/chat-server";
import { createChatMessage, sendQuestion } from "@/server/chat-server-fetch";
import { fetchWorkflowByWorkflowId } from "@/server/api-server";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { marked } from "marked";
import MixinUser from "@/components/MixinUser.vue";
import { loggingError } from "@/server/error-log-server.js";
import { copy } from "@/js/common/copy";
export default {
  mixins: [MixinUser],
  props: {
    workflowId: {
      type: String,
      default: "",
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      msgList: [],
      askMavenHistory: [],
      isDragFile: false,

      fileExt: "",
      fileName: "",
      fileUrl: "",
      inputText: "",
      videoUrl: "",
      referencesList: [],
      loading: false,
      showGroupMenu: false,
      currentGroupId: null,
      showError: false,
      showPlayer: false,
      currentWorkflowId: null,
      isVoiceInput: false,
      showClearDialog: false,
      activeModal: null,
      streamResponseT: 0,
    };
  },
  async created() {
    this.getChatContentByWorkflowId();
  },
  beforeDestroy() {
    this.resetMaven();
  },
  computed: {
    ...mapGetters("mavenChat", ["getters_maven_user_group_list", "getters_maven_group_dictionary"]),
    ...mapGetters("auth", ["getter_user_organization", "getter_user_id"]),
    ...mapGetters("workflowPlayer", ["getters_steps"]),
    ...mapState("maven2", ["chatList", "selectedChatId", "chatType"]),
    currentGroupName() {
      return this.currentGroupId ? this.getters_maven_group_dictionary[this.currentGroupId] : this.$t("all.all");
    },
    apiPayloadGroup() {
      return this.currentGroupId
        ? [this.currentGroupId]
        : this.getters_maven_user_group_list.map(({ id }) => id).slice(1);
    },
    showGroupLabelAndIcon() {
      return this.selectedChatId === -1 && !this.loading;
    },
    isButtonDisabled() {
      return !this.inputText.trim() || this.loading;
    },
  },
  components: {
    Empty: () => import("./Empty.vue"),
    WorkflowReferenceList: () => import("@/components/DMavenChat/WorkflowReferenceList.vue"),
    DImage: () => import("@/components/ui_components/DImage.vue"),
    AskMavenRecommended: () => import("@/components/Q&A/Q&ARecommended.vue"),
    AskMavenAnswer: () => import("@/components/DAskMaven/AskMavenAnswer.vue"),
    AskMavenLoading: () => import("@/components/DAskMaven/AskMavenLoading.vue"),
    AskMavenError: () => import("@/components/DAskMaven/AskMavenError.vue"),
    AskMavenIntro: () => import("@/components/DAskMaven/AskMavenIntro.vue"),
    DConfirmationPopup: () => import("@/components/DPopup/DConfirmationPopup.vue"),
    QASources: () => import("@/components/Q&A/Q&ASources.vue"),
  },
  async mounted() {
    this.loading = true;
    await this.fetchChatPresetQuestions(this.workflowId);
    this.loading = false;
  },
  methods: {
    ...mapActions("mavenChat", ["fetchMavenUserGroupList"]),
    ...mapActions("maven2", ["resetMaven", "fetchChatPresetQuestions"]),
    ...mapActions("workflowPlayer", ["setCurrentStepIdx"]),
    ...mapMutations("maven2", ["SET_CHAT_ID"]),
    openModal({ modalId }) {
      this.activeModal = modalId;
    },
    closeModal() {
      this.activeModal = null;
    },
    async sendMessage(txt) {
      this.loading = true;

      if (this.selectedChatId === -1) {
        await this.createChat(txt);
      } else {
        await this.sendMessageToChatId(txt);
      }
      this.loading = false;
    },
    async createChat(txt) {
      try {
        const query = txt ? txt : this.inputText;
        if (!query || !/\S/.test(query)) {
          return (this.loading = false);
        }
        this.inputText = "";
        this.streamResponseT = 0;
        const self = this;
        await createChatMessage(
          {
            query,
            type: "workflow",
            groups: this.apiPayloadGroup,
            workflowId: this.workflowId,
            streaming: true,
          },
          (text) => this.streamResponseCallback(text, query)
        );
        this.$forceUpdate();
      } catch (e) {
        console.log(e);
        loggingError(e);
        this.displayError();
      } finally {
        const chatId = this.askMavenHistory?.[0]?.chatId;
        if (chatId) {
          this.SET_CHAT_ID(chatId);
        }
      }
    },
    async sendMessageToChatId(txt) {
      try {
        const currentChatId = this.selectedChatId;
        const query = txt ? txt : this.inputText;

        if (!query || !/\S/.test(query)) {
          return (this.loading = false);
        }
        this.inputText = "";
        this.streamResponseT = 0;
        const self = this;
        const res = await sendQuestion(
          this.selectedChatId,
          {
            query,
            type: "workflow",
            groups: this.apiPayloadGroup,
            chatId: this.selectedChatId,
            streaming: true,
          },
          (text) => this.streamResponseCallback(text, query)
        );
        this.$forceUpdate();
      } catch (e) {
        console.log(e);
        loggingError(e);
        this.displayError();
      }
    },
    streamResponseCallback(text, query) {
      if (text.includes(": ping")) return;
      const responses = text.split("\r\n\r\n");
      if (this.loading) this.loading = false;
      self.loading = false;
      const data = responses.map((res) => {
        if (!res) {
          return "";
        }
        return JSON.parse(res.substring(6));
      });
      const streamResponse = data.reduce(
        (prev, curr) => {
          if (!curr) return prev;
          return {
            ...prev,
            item: { ...prev.item, ...curr.item, content: prev.item.content + curr.item.content },
          };
        },
        { success: true, code: 0, message: "success", item: { content: "" } }
      );
      const { content, references, followUpQuestions, chatId, messageId } = streamResponse.item;
      if (this.streamResponseT === 0) {
        this.askMavenHistory.unshift({
          question: query,
          ...(content && { answer: content }),
          workspaces: this.apiPayloadGroup,
          ...(references && { references }),
          ...(followUpQuestions && { followUpQuestions }),
          ...(chatId && { chatId }),
          ...(messageId && { answerId: messageId }),
        });
      } else {
        const history = this.askMavenHistory[0];
        if (references) {
          history.references = references;
        }
        if (followUpQuestions) {
          history.followUpQuestions = followUpQuestions;
        }
        if (content) {
          history.answer += content;
        }
        if (chatId) {
          history.chatId = chatId;
        }
        if (messageId) {
          history.answerId = messageId;
        }
      }

      this.streamResponseT++;
    },
    loadAskMavenData(msgList) {
      this.askMavenHistory = [];
      for (let i = 0; i < msgList.length; i += 2) {
        this.askMavenHistory.unshift({
          question: msgList[i].content,
          questionId: msgList[i].messageId,
          workspaces: msgList[i].groups,
          answer: msgList[i + 1].content,
          answerId: msgList[i + 1].messageId,
          answerLike: msgList[i + 1].like,
          references: msgList[i + 1].references,
          followUpQuestions: msgList[i + 1].followUpQuestions,
        });
      }
    },
    async getChatHistory(id, needClear = true, needLoading = false) {
      if (!needLoading) {
        this.loading = true;
      }
      if (needClear) {
        this.askMavenHistory = [];
      }
      const res = await getChatById(id);
      if (!res.ok) return;
      const { item } = res.data;
      try {
        this.loadAskMavenData(JSON.parse(JSON.stringify(item.history)));
      } catch (e) {
        console.log(e);
      }
      if (!needLoading) {
        this.loading = false;
      }
    },
    parsingMarkdown(txt, references) {
      try {
        const dom = marked(txt, { breaks: true, gfm: true });
        const parser = new DOMParser();
        const doc = parser.parseFromString(dom, "text/html");
        if (references) {
          // Find all text nodes in the document
          const textNodes = this.getTextNodes(doc.body);

          // Iterate over each text node
          textNodes.forEach((node) => {
            const text = node.textContent;

            // Match numbers inside brackets [1]
            const regex = /\[(\d+)\]/g;
            const matches = text.match(regex);

            if (matches && !this.isWorkflowLevel) {
              let replacedText = text;

              // Replace each matched number with an anchor element
              matches.forEach((match) => {
                const number = match.slice(1, -1);
                if (number <= references.length) {
                  replacedText = replacedText.replace(
                    match,
                    `<button class="markdownReference stepReference" data-stepId="${
                      references[number - 1].id
                    }">${number}</button>`
                  );
                }
              });

              // Replace the text node with the updated content
              const tempElement = document.createElement("div");
              tempElement.innerHTML = replacedText;
              node.parentNode.replaceChild(tempElement, node);
            }
          });
        }
        const self = this;
        setTimeout(() => {
          const els = document.querySelectorAll(".stepReference");
          for (const el of els) {
            const newEl = el.cloneNode(true);
            el.parentNode.replaceChild(newEl, el);

            newEl.addEventListener("click", (e) => {
              console.log(e.target.dataset);
              self.openPlayer({ stepId: e.target.dataset.stepid });
            });
          }
        }, 0);

        return doc.documentElement.innerHTML;
      } catch (e) {
        return txt;
      }
    },
    // Helper function to get all text nodes in a DOM element
    getTextNodes(element) {
      const textNodes = [];

      const walk = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null, false);
      let node;

      while ((node = walk.nextNode())) {
        textNodes.push(node);
      }

      return textNodes;
    },
    async getReferences(msg) {
      if (!msg.references || msg.references.length === 0) {
        return;
      }
      let references = msg.references;
      if (typeof references[0] === "object") {
        references = references
          .filter(function (el) {
            return el.type === "workflow";
          })
          .map((reference) => reference.id);
      }
      const workflowIds = references;
      const promise = [];
      for (const id of workflowIds) {
        promise.push(fetchWorkflowByWorkflowId(id));
      }
      const res = await Promise.all(promise);
      this.referencesList.push({ id: msg.messageId, workflows: res.map(({ data }) => data.item) });
    },
    pickerReferences(msgId) {
      const t = this.referencesList.find(({ id }) => id === msgId);
      if (!t) {
        return [];
      }
      return t.workflows;
    },
    openPlayer({ stepId }) {
      const idx = this.getters_steps.findIndex(({ id }) => id === stepId);
      this.setCurrentStepIdx({ index: idx });
      this.$emit("addon-click");
      return;
    },
    closePlayer() {
      this.currentWorkflowId = null;
      this.showPlayer = false;
    },
    chooseScriptFile() {
      const fileEl = this.$refs.file;
      if (fileEl) {
        fileEl.click();
      }
    },
    parseParticularSymbol(doc, addClass = false) {
      const childs = doc.body.childNodes;
      const reg = /{:.(.+)}/;
      for (const child of childs) {
        const text = child.innerText;
        if (text) {
          const match = text.match(reg);
          if (match) {
            if (addClass) {
              child.classList.add(match[1]);
            }
            child.innerText = text.replace(match[0], "");
          }
        }
      }
    },
    async copy(content) {
      const html = marked.parse(content);
      const parse = new DOMParser();
      const doc = parse.parseFromString(html, "text/html");
      this.parseParticularSymbol(doc);
      const res = doc.documentElement.innerText;
      copy(res);
      this.openAlert({ message: this.$t("builder.copied"), type: "success" });
    },
    downloadText(text, filename) {
      const blob = new Blob([text], { type: "text/plain" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();

      // 释放URL对象
      URL.revokeObjectURL(url);
    },
    async sendThreadLike({ thread, isLike }) {
      thread.answerLike = isLike;
      try {
        await likeMsg(this.selectedChatId, thread.answerId, { like: isLike });
        this.getChatHistory(this.selectedChatId, false, true);
      } catch (e) {
        console.log(e);
        loggingError(e);
        this.displayError();
      }
    },
    handleSendEvent({ question }) {
      this.sendMessage(question);
    },
    displayError() {
      this.showError = true;
    },
    async getChatContentByWorkflowId() {
      const res = await getChatByWorkflowId(this.workflowId);
      if (res.ok && res.data.items.length > 0) {
        const id = Number(res.data.items[0].id);
        this.SET_CHAT_ID(id);
        this.getChatHistory(id, false, true);
      }
    },
    async clearInWorkflowLevel() {
      this.askMavenHistory = [];
      this.loading = true;
      await clearChatByWorkflowId(this.workflowId);
      this.resetMaven();
      this.fetchChatPresetQuestions(this.workflowId);
      this.loading = false;
    },
  },
  watch: {
    chatList(list) {
      if (list.length === 0) {
        this.msgList = [];
        this.askMavenHistory = [];
        this.SET_CHAT_ID(-1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  color: var(--dGrey6-color);
}

.Button {
  &.Small {
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 80px;
    background-color: var(--dGrey2-color);
    border: 1px solid var(--dGrey3-color);
  }
}

.MavenStage {
  height: 100%;
  background-color: var(--dGrey1-color);
}

.AskMaven {
  max-height: 100%;
  overflow-y: auto;
  color: var(--dGrey6-color);
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  &.fullScreen {
    height: calc(100vh - 80px);
  }
  //align-items: center;

  .AskMaven__Error {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.AskMaven__Thread {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  overflow: auto;
  flex: 1;

  &.clearHistory {
    padding: 24px 0;
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    ::v-deep button {
      flex: 1;
    }
    .clearBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      flex: 1;
    }
  }

  &.isInput {
    bottom: 0;
    position: sticky;
    padding-top: 40px;
    padding-bottom: 20px;
    background: linear-gradient(180deg, transparent, var(--dGrey1-color));
    &.inputHeight {
      flex: 0 200px;
      &--small {
        flex: 0 90px;
      }
    }
  }

  & + .isLoading {
    margin-top: 40px;
  }

  &.isLoading {
    pointer-events: none;

    %skeleton-card {
      cursor: default;
      border-radius: 4px;
      margin-bottom: 10px;
      background-color: var(--dGrey2-color);
      background-image: linear-gradient(
        90deg,
        var(--dGrey2-color) 0px,
        var(--dGrey3-color) 80px,
        var(--dGrey2-color) 160px
      );
      background-size: 200%;
      animation: skeleton-animation 2.5s infinite linear;
    }

    %skeleton-text {
      width: 100%;
      cursor: default;
      border-radius: 24px;
      margin-bottom: 10px;
      background-color: var(--dGrey4-color);
      background-image: linear-gradient(
        90deg,
        var(--dGrey4-color) 0px,
        var(--dGrey5-color) 80px,
        var(--dGrey4-color) 160px
      );
      background-size: 200%;
      animation: skeleton-animation 2.5s infinite linear;
    }

    .AskMaven__ItemTitle {
      gap: 4px;
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      .AskMaven__ItemTitle_Skeleton {
        @extend %skeleton-text;
        height: 16px;

        &:last-child {
          width: 80%;
        }
      }
    }

    .AskMaven__ItemAnswer {
      gap: 4px;
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      .AskMaven__ItemAnswer_Skeleton {
        @extend %skeleton-text;
        height: 8px;

        &:last-child {
          width: 80%;
        }
      }
    }

    .AskMaven__ItemSourcesItem {
      @extend %skeleton-card;
      height: 108px;
      border: none;
    }

    .AskMaven__AddonsItem {
      opacity: 1;
    }

    .AskMaven__AddonsItem_Skeleton {
      @extend %skeleton-card;
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes skeleton-animation {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: -100%;
  }
}

.AskMaven__ItemAnswer {
  font-size: 16px;
  color: var(--brand-white);

  &:last-child,
  *:last-child {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 1em;

    li {
      margin-bottom: 0.25em;
    }
  }
}

.AskMaven__ThreadContainer {
  gap: 48px;
  width: 920px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  &.workflowLevel {
    display: block;
    width: 100%;
  }

  &.isWorkspace {
    gap: 8px;
    padding-left: 10px;
    align-items: center;
    justify-content: flex-start;
  }

  &.isInput {
    justify-content: flex-start;
    &.workflowLevel {
      background: var(--dGrey1-color);
    }
  }
}

.AskMaven__ItemWrapper {
  padding-bottom: 40px;
  border-bottom: 1px solid var(--dGrey3-color);

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.AskMaven__Item {
  gap: 32px;
  display: flex;
  flex: 0 0 600px;
  text-align: left;
  max-width: 600px;
  flex-direction: column;

  .AskMaven__ItemTitle {
    font-size: 28px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: var(--brand-white);
  }

  .AskMaven__Object {
    gap: 16px;
    display: flex;
    flex-direction: column;

    .AskMaven__Object_Title {
      color: var(--dGrey6-color);
    }
  }
}

.AskMaven__ActionButton {
  gap: 4px;
  display: flex;
  padding: 8px 12px;
  border-radius: 80px;
  align-items: center;
  color: var(--dGrey6-color);
  border: 1px solid transparent;
  transition: border 0.15s ease-in, background-color 0.15s ease-in, box-shadow 0.15 ease-in;

  &:hover {
    color: var(--brand-white);
    background-color: var(--dGrey3-color);
    border-color: var(--dGrey4-color);
  }

  &:active {
    background-color: var(--dGrey4-color);
    border-color: var(--dGrey5-color);
    box-shadow: 0px 0px 2px 0px #33353a;
  }

  &:disabled {
    opacity: 0.4;
  }

  &.isPrimary {
    color: var(--brand-white);
    background: var(--brand-blue);

    &:hover {
      filter: brightness(120%);
    }
  }

  &.isIcon {
    padding: 0;
    width: 32px;
    height: 32px;
    justify-content: center;
    background-color: var(--dGrey3-color);
    border-color: var(--dGrey4-color);

    &:hover {
      background-color: var(--dGrey4-color);
      border-color: var(--dGrey5-color);
    }

    &:active {
      background-color: var(--dGrey5-color);
      border-color: var(--dGrey6-color);
    }
  }
}

.AskMaven__Addons {
  flex: 1;
  top: 24px;
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 160px 80px;
  gap: 12px 12px;
  grid-template-areas:
    "Featured Featured"
    ". ."
    ". .";

  &:hover {
    .AskMaven__AddonsItem {
      opacity: 0.8;
    }
  }

  .AskMaven__AddonsItem {
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    transition: transform 0.075s ease-in;

    &:first-child {
      border-radius: 12px;
      grid-area: Featured;
    }

    .v-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    button {
      z-index: 1;
      right: 4px;
      bottom: 4px;
      position: absolute;
    }

    &:hover {
      opacity: 1;

      button {
        color: var(--brand-white);
        background-color: var(--dGrey3-color);
        border: 1px solid var(--dGrey4-color);
      }
    }

    &:active {
      transform: scale(0.96);
    }
  }
}

.Modal {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--utilities-overlay);

  .Modal__Container {
    overflow: auto;
    position: relative;
    border-radius: 16px;
    transition: transform 0.2s;
    background-color: var(--dGrey1-color);
    border: 1px solid var(--dGrey2-color);
  }

  .Modal__Header {
    top: 0;
    display: flex;
    position: sticky;
    align-items: center;
    padding: 16px 24px;
    justify-content: space-between;
    background-color: var(--dGrey1-color);
  }

  .Modal__Body {
    padding: 24px;
  }

  .Modal__Header + .Modal__Body {
    padding-top: 0;
  }

  &.isActive {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s, visibility 0.2s 0s;

    .Modal__Container {
    }
  }

  &.isInactive {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }

  &.isRight {
    align-items: center;
    justify-content: flex-end;

    .Modal__Container {
      top: 8px;
      right: 8px;
      bottom: 8px;
      width: 40vw;
      max-width: 560px;
      position: absolute;
      transform: translateX(50%) scale(0.985);
      transition: transform 0.4s, opacity 0.2s;
    }

    &.isInactive {
      .Modal__Container {
      }
    }

    &.isActive {
      .Modal__Container {
        transform: translateX(0) scale(1);
      }
    }
  }
}

// Maven 2.0
.DMaven_2_0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 160px 40px 228px;
  height: 100%;
  background-color: #121314;

  .message__list {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-bottom: 12px;
    width: 100%;
    overflow: auto;

    &.center {
      justify-content: center;
      flex-direction: row;
      align-items: center;
    }

    &__item {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      justify-content: flex-start;
      color: white;
      margin-top: 24px;

      &__text {
        text-align: left;
        font-size: 18px;
        line-height: 28px;
        color: #bcc0cb;
      }

      &__action {
        display: flex;
        padding-left: 32px;
        cursor: pointer;
        visibility: hidden;
        gap: 12px;
      }
    }
  }

  .default__chat__btns {
    display: flex;
    color: #fff;

    .btn {
      background: #1d1e21;
    }
  }

  &__warning {
    color: #9397a6;
    margin-top: 12px;
  }

  .script__actions {
    display: flex;
    justify-content: center;
    gap: 4px;
  }

  .loading__wrap {
    margin: 0 auto 20px auto;
  }

  .message__wrap {
    &:hover {
      .message__list__item__action {
        visibility: visible;
      }
    }
  }
}

.Maven__DGroupMenu {
  position: fixed;
  top: 70px;
  left: 0px;
  width: 100vw;
  height: calc(100vh - 70px);
}

.markdown::v-deep {
  .markdownReference {
    width: 20px;
    height: 20px;
    font-size: 12px;
    margin-right: 2px;
    text-decoration: none;
    text-align: center;
    border-radius: 80px;
    color: var(--dGrey6-color);
    background-color: var(--dGrey3-color);
    border: 1px solid transparent;
    display: inline-block;
  }

  h2 {
    margin-bottom: 16px;
    color: white;
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -2px;
    margin-top: 32px;

    &:first-child {
      margin-top: 0px;
    }
  }

  h3 {
    color: white;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -1px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .tool::before {
    content: "";
    width: 20px;
    height: 20px;
    background: url("@/img/icon-tools.svg");
    background-size: 100% 100%;
    display: inline-block;
  }

  .safe-warning::before {
    content: "";
    width: 20px;
    height: 20px;
    background: url("@/img/icon-warning.svg");
    background-size: 100% 100%;
    display: inline-block;
  }

  .tip::before {
    content: "";
    width: 20px;
    height: 20px;
    background: url("@/img/icon-lighbulb.svg");
    background-size: 100% 100%;
    display: inline-block;
  }

  h4 {
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  strong {
    color: white;
    margin-bottom: 8px;
  }

  &.script {
    h3 {
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background: url("@/img/icon-message-quote.svg");
        background-size: cover;
        display: inline-block;
      }
    }

    h4 {
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        background: url("@/img/icon-clapboard.svg");
        background-size: 100% 100%;
        display: inline-block;
      }
    }
  }
}

.WorkspaceMenu {
  top: 0;
  left: 0;
  gap: 8px;
  width: 100%;
  padding: 24px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  &.Static {
    padding-left: 0;
    position: static;
    justify-content: flex-start;
  }

  .WorkspaceMenu-label {
    font-size: 16px;
    color: var(--dGrey6-color);
  }

  .WorkspaceMenu__Button {
    gap: 8px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    padding: 8px 12px;
    align-items: center;
    border-radius: 80px;
    color: var(--dGrey6-color);
    background-color: var(--dGrey3-color);
    border: 1px solid var(--dGrey4-color);

    .currentWorkspace {
      display: block;
      max-width: 146px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover:not(:disabled) {
      color: var(--brand-white);
    }
  }
}

.InputWrap {
  gap: 12px;
  width: 100%;
  padding: 12px;
  display: flex;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background: var(--dGrey2-color);
  border: 1px solid var(--dGrey3-color);
  transition: all 0.15s ease-in;

  &:not(.isDisabled):hover {
    background: var(--dGrey3-color);
    border: 1px solid var(--dGrey4-color);
  }

  &:focus-within {
    background: var(--dGrey3-color);
    border: 1px solid var(--dGrey5-color);
  }

  &.isTall {
    flex-direction: column;
    align-items: flex-end;

    textarea {
      width: 100%;
      resize: none;
      min-height: 40px;
      max-height: 280px;
      font-size: 16px;
      color: var(--brand-white);

      &:focus,
      &:active {
        outline: none;
        background: transparent;
        border: none;
      }
    }
  }

  .voice__input {
    color: white;
  }

  input {
    flex: 1;
    width: 100%;
    color: white;

    &:focus-visible {
      outline: 0px;
    }
  }

  .InputSend {
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 32px;
    align-items: center;
    justify-content: center;
    background: var(--brand-blue);
    border: 1px solid transparent;
    color: var(--brand-white);

    &:hover:not(:disabled) {
      filter: brightness(120%);
    }

    &:active {
      filter: brightness(130%);
    }

    &:disabled {
      color: var(--dGrey6-color);
      background-color: var(--dGrey4-color);
      border-color: transparent;
    }
  }

  .InputButtonsContainer {
    display: inline-flex;
    align-items: center;
    gap: 12px;
  }

  button {
    padding: 10px;
    border-radius: 80px;
    border: 1px solid #3d3f45;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.DLeftSideMenu + .App__content {
  @media (max-width: 920px) {
    .AskMaven__Thread {
      align-items: stretch;
    }

    .AskMaven__ThreadContainer {
      width: 100%;
    }

    .AskMaven__Item {
      flex: auto;
      width: 100%;
    }

    .AskMaven__Addons {
      display: none;
    }
  }
}

.DLeftSideMenu.active + .App__content {
  @media (max-width: 1200px) {
    .AskMaven__Thread {
      align-items: stretch;
    }

    .AskMaven__ThreadContainer {
      width: 100%;
    }

    .AskMaven__Item {
      flex: auto;
      width: 100%;
    }

    .AskMaven__Addons {
      display: none;
    }
  }
}
</style>
