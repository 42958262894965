const DB_SETTINGS = {
  global: {
    backendType: "firebase",
    databaseType: "firestore",
    authType: "firebase",
  },
  china: {
    backendType: "ali",
    databaseType: "mongoDB",
    authType: "authing",
  },
};

export default {
  env: process.env.VUE_APP_API_ENV, // dev, prod
  firebaseProxyHost: "googleapis-com.stephanie.ai",
  ...DB_SETTINGS[process.env.VUE_APP_BACKEND_TYPE],
  firebaseAPI: {
    dev: {
      apiKey: "AIzaSyBG5xBkoMRFXpU2dFCNcysdAmdn9eJsI5M",
      authDomain: "deephow-dev.firebaseapp.com",
      databaseURL: "https://deephow-dev.firebaseio.com",
      projectId: "deephow-dev",
      storageBucket: "deephow-dev.appspot.com",
      messagingSenderId: "928515275242",
      appId: "1:928515275242:web:2fbb8f29ea748f40bc9a93",
      measurementId: "G-J17RQFZ6YC",
    },
    prod: {
      apiKey: "AIzaSyBTAJQ9qNMbaPaIU7GcK59Vjr7GGc99xjw",
      authDomain: "deephow-prod.firebaseapp.com",
      databaseURL: "https://deephow-prod.firebaseio.com",
      projectId: "deephow-prod",
      storageBucket: "deephow-prod.appspot.com",
      messagingSenderId: "671982572211",
      appId: "1:671982572211:web:6787e99a3659eccb8ad6c6",
    },
    dev2: {
      backendType: "firebase",
      apiKey: "AIzaSyC205Ut8V8BMjUyH8jut1yfz9lPdKGWsZc",
      authDomain: "deephow-dev2.firebaseapp.com",
      databaseURL: "https://deephow-dev2.firebaseio.com",
      projectId: "deephow-dev2",
      storageBucket: "deephow-dev2.appspot.com",
      messagingSenderId: "880755444490",
      appId: "1:880755444490:web:18dcc144a98c24370c88f3",
    },
  },
  authingAPI: {
    dev: {
      appId: "600ee05a00d0cacef0a78c0b",
    },
    prod: {
      appId: "601eb0a2964f91f2f6103721",
    },
  },
  service: {
    dev: {
      language: "https://dev-language-server.deephow.app/",
      video: "https://dev-video-server.deephow.app/",
      search: "https://dev-text-search-server.deephow.app/",
      crossModalitySearch: "https://dev-cross-modality-search.deephow.app/",
      admin: "https://dev-admin-server.deephow.app/",
      sign: "https://dev-sign-server.deephow.app/",
      qrcode: "https://dev-qrcode-server.deephow.app/",
      analytics: "https://dev-analytics-server.deephow.app/",
      recommend3: "https://dev-recommendation-api.deephow.app",
      dataExporter: "https://dev-analytics-exporter-server.deephow.app/v1/",
      workflow: "https://dev-workflow-server.deephow.app",
      deephowApiServer: "https://dev-deephow-api.deephow.app",
      externalApi: "https://dev-deephow-external-api.deephow.app",
      chatServer: "https://dev-chat-api.deephow.app",
      azureSpeechServer: "https://dev-azure-speech-api.deephow.app",
      visualizationServer: "https://dev-visualization-api.deephow.app",
      ulApiServer: "https://dev-ul-integration.deephow.app",
      summarizationServer: "https://dev-summarization-api.deephow.app",
      china: {
        language: "https://dev-language-server.deephow.net/",
        video: "https://dev-video.deephow.net:8080",
        search: "https://dev-text-search-server.deephow.net/",
        admin: "https://dev-admin-server.deephow.net/",
        sign: "https://dev-sign-server.deephow.net/",
        qrcode: "https://dev-qrcode-server.deephow.net/",
        analytics: "https://dev-analytics-server.deephow.net/",
        recommend3: "https://dev-recommendation.deephow.net",
        database: "https://dev-database-server.deephow.net/",
        websocket: "wss://dev-websocket-server.deephow.net/wss",
        workflow: "https://dev-workflow-server.deephow.net:8081",
        dataExporter: "https://dev-analytics-exporter-server.deephow.net/v1/",
        deephowApiServer: "https://dev-deephow-api.deephow.net",
        externalApi: "https://dev-external-api.deephow.net",
        ulApiServer: "https://ul-integration-3oqy6iorxq-uc.a.run.app",
      },
    },
    prod: {
      language: "https://language-server.deephow.app/",
      video: "https://video-server.deephow.app",
      sign: "https://sign-server.deephow.app/",
      search: "https://text-search-server.deephow.app/",
      crossModalitySearch: "https://cross-modality-search.deephow.app",
      admin: "https://admin-server.deephow.app/",
      qrcode: "https://qrcode-server.deephow.app/",
      analytics: "https://analytics-server.deephow.app/",
      recommend3: "https://recommendation-api.deephow.app",
      dataExporter: "https://analytics-exporter-server.deephow.app/v1/",
      workflow: "https://workflow-server.deephow.app",
      deephowApiServer: "https://deephow-api.deephow.app",
      externalApi: "https://deephow-external-api.deephow.app",
      chatServer: "https://chat-api.deephow.app",
      azureSpeechServer: "https://azure-speech-api.deephow.app",
      visualizationServer: "https://visualization-api.deephow.app",
      ulApiServer: "https://ul-integration.deephow.app",
      summarizationServer: "https://summarization-api.deephow.app",
      china: {
        language: "https://language-server.deephow.net/",
        video: "https://video-server.deephow.net:8080",
        search: "https://text-search-server.deephow.net/",
        admin: "https://admin-server.deephow.net/",
        sign: "https://sign-server.deephow.net/",
        qrcode: "https://qrcode-server.deephow.net/",
        analytics: "https://analytics-server.deephow.net/",
        recommend3: "https://recommendation.deephow.net",
        database: "https://database-server.deephow.net/",
        websocket: "wss://websocket-server.deephow.net/wss",
        workflow: "https://workflow-server.deephow.net:8081",
        dataExporter: "https://analytics-exporter-server.deephow.net/v1/",
        deephowApiServer: "https://deephow-api.deephow.net",
        externalApi: "https://external-api.deephow.net",
        ulApiServer: "https://ul-integration-kfk24stg4a-uc.a.run.app",
      },
    },
  },
  download: {
    dev: {
      windowsCapture: "https://storage.googleapis.com/deephow-public/app/deephow_windows_capture.msi",
      windowsCaptureZip: "https://storage.googleapis.com/deephow-public/app/deephow_windows_capture.zip",
      china: {
        windowsCapture: "https://public.deephow.net/apps/DeephowChinaInstaller.msi",
        windowsCaptureZip: "https://public.deephow.net/apps/DeephowChinaCapture.zip",
        windowsZipTutorial: "https://deephow.ai/p/KAWGlQgU838d9OjGWdao",
        privacyPolicyZh: "https://public.deephow.net/policies/deephow-privacy-policy.html",
        userAgreementZh: "https://public.deephow.net/policies/deephow-user-agreement.html",
        privacyPolicyPDF: "https://public.deephow.net/policies/DCP.pdf",
        abiUploadPolicyChina:
          "https://public.deephow.net/policies/deephow-abi-photograph-video-recording-privacy-statement.html",
      },
      privacyPolicyEn: "https://storage.googleapis.com/deephow-public/policies/deephow-com-privacy-policy.html",
      privacyPolicyZh: "https://public.deephow.net/policies/deephow-privacy-policy.html",
      privacyPolicyPDF: "https://storage.googleapis.com/deephow-public/policies/DCP.pdf",
      userAgreementZh: "https://public.deephow.net/policies/deephow-user-agreement.html",
      abiUploadPolicyGlobal:
        "https://storage.googleapis.com/deephow-public/policies/deephow-abi-photograph-video-recording-privacy-statement.html",
    },
    prod: {
      windowsCapture: "https://storage.googleapis.com/deephow-public/app/deephow_windows_capture.msi",
      windowsCaptureZip: "https://storage.googleapis.com/deephow-public/app/deephow_windows_capture.zip",
      windowsZipTutorial: "https://deephow.ai/p/KAWGlQgU838d9OjGWdao",
      china: {
        windowsCapture: "https://public.deephow.net/apps/DeephowChinaInstaller.msi",
        windowsCaptureZip: "https://public.deephow.net/apps/DeephowChinaCapture.zip",
        privacyPolicyZh: "https://public.deephow.net/policies/deephow-privacy-policy.html",
        userAgreementZh: "https://public.deephow.net/policies/deephow-user-agreement.html",
        privacyPolicyPDF: "https://public.deephow.net/policies/DCP.pdf",
        abiUploadPolicyChina:
          "https://public.deephow.net/policies/deephow-abi-photograph-video-recording-privacy-statement.html",
      },
      privacyPolicyEn: "https://storage.googleapis.com/deephow-public/policies/deephow-com-privacy-policy.html",
      privacyPolicyZh: "https://public.deephow.net/policies/deephow-privacy-policy.html",
      privacyPolicyPDF: "https://storage.googleapis.com/deephow-public/policies/DCP.pdf",
      userAgreementZh: "https://public.deephow.net/policies/deephow-user-agreement.html",
      abiUploadPolicyGlobal:
        "https://storage.googleapis.com/deephow-public/policies/deephow-abi-photograph-video-recording-privacy-statement.html",
    },
  },
  collectionVersioning: {
    dev: {
      collections: ["transcriptions"],
      maxVersionsAllowed: 1000,
    },
    prod: {
      collections: ["transcriptions"],
      maxVersionsAllowed: 1000,
    },
  },
  analyticsType: "matomo",
  heap: {
    dev: "3249579741",
    prod: "922362239",
  },
  matomo: {
    apiHost: process.env.VUE_APP_MATAMO_API_HOST,
    siteId: process.env.VUE_APP_MATAMO_SITE_ID,
    containerId: process.env.VUE_APP_CONTAINER_ID,
  },
  EditorVersion: "3.0", //  1.0(deprecated), 2.0(deprecated), 2.5, 3.0
};
